'use client';

import Link from 'next/link';
import { APP_NAME } from '@/constants';
import { Metadata } from 'next';
export const metadata: Metadata = {
  title: `Oops! · ${APP_NAME}`,
  robots: {
    index: false,
    follow: false
  }
};
export default function ErrorPage() {
  return <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-primary" />
        <h1 className="text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Oops, something went wrong!
        </h1>
        <p className="mt-4 text-muted-foreground">
          We&apos;re sorry, but an unexpected error has occurred. Please try
          again later or contact support if the issue persists.
        </p>
        <div className="mt-6">
          <Link href="/" className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" prefetch={false} data-sentry-element="Link" data-sentry-source-file="error.tsx">
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>;
}